import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="services">
<div className="container">
    <div className="row">
        <div className="col-12">
            <div className="skill-bx wow zoomIn">
                <h2>خدماتنا</h2>
                <p>خدمات البحوث والعروض عندنا مصممة خصيصا لك، انطلق في مشوارك الدراسي معانا.<br></br> تعال نكتشف الحياة الدراسية سوا.</p>
                <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                    <div className="item">
                        <img src={meter1} alt="Service 1 Image" />
                        <h5>السرعة في إنجاز العروض التقديمية</h5>
                    </div>
                    <div className="item">
                        <img src={meter1} alt="Service 1 Image" />
                        <h5>خدمات الأعمال</h5>
                    </div>
                    <div className="item">
                        <img src={meter2} alt="Service 2 Image" />
                        <h5>السرعة في الأبحاث الطويلة</h5>
                    </div>
                    <div className="item">
                        <img src={meter3} alt="Service 3 Image" />
                        <h5>السرعة في كتابة جميع الأبحاث</h5>
                    </div>
                </Carousel>
            </div>
        </div>
    </div>
</div>

        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
