import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/header-b7th.png';
import navIcon1 from '../assets/img/tiktok.png';
import navIcon2 from '../assets/img/twitter.png';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>الرئيسية</Nav.Link>
              <Nav.Link href="#services" className={activeLink === 'services' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('services')}>خدماتنا</Nav.Link>
              <Nav.Link href="#testimonial" className={activeLink === 'testimonial' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('testimonial')}>تقييمات العملاء</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.tiktok.com/@b7th.space" target="_blank"><img src={navIcon1} alt="" /></a>
                <a href="https://www.twitter.com/b7thspace/" target="_blank"><img src={navIcon2} alt="" /></a>
                <a href="https://www.instagram.com/b7th.space/" target="_blank"><img src={navIcon3} alt="" /></a>
              </div>
              <a href="https://wa.me/966577373424" target="_blank" rel="noopener noreferrer">
                  <button className="vvd"><span>🤙 تواصل معنا </span></button>
              </a>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}
