import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/whatsapp.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyAcYRHHWiAe3rfYDk6srna5nbL9lBlUxIY",
  authDomain: "b7thspace-7c94e.firebaseapp.com",
  projectId: "b7thspace-7c94e",
  storageBucket: "b7thspace-7c94e.appspot.com",
  messagingSenderId: "876046797370",
  appId: "1:876046797370:web:839c8aca4e0cc5e1460303",
  measurementId: "G-KCWS7NNF4C"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const TiktokAd = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "نساعدك في البحوث 🤫", "نسوي عروضك التقديمية 👀", "والمزيد! 🔥" ];
  const period = 1500000;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(200);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }


  function handleClick(eventName) {
    console.log("handleClick triggered with:", eventName); // Added for debugging
    
    // Log the specific click event
    logEvent(analytics, eventName);
  
    // Handle specific actions based on the eventName
    if (eventName === 'whatsapp_button_click') {
      console.log("Executing WhatsApp action"); // Added for debugging
      window.open('https://wa.me/966577373424', '_blank');
    }
  }
  
  return (
    <section className="banner" id="home">
      <Container dir='rtl'>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
          <TrackVisibility>
                {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                    <span className="tagline">مرحباً في b7th.space</span>
                    <h3>{`وش تنتظر؟🤔 تواصل معنا على الواتس اب وازهلها!🔥`}</h3>
                    <p>تراك مو لحالك! فضاء الأبحاث معاك في كل خطوة!🚀</p>

                    <button onClick={() => handleClick('whatsapp_button_click')}>
                            <ArrowRightCircle size={25} />ابدأ الآن
                    </button>

                </div>}
            </TrackVisibility>

          </Col>
          <Col xs={12} md={6} xl={5} className="d-flex justify-content-center align-items-center">
            <TrackVisibility>
                {({ isVisible }) =>
                    <div className={isVisible ? "animate__animated animate__zoomIn text-center" : "text-center"}>
                        {/* Attach onClick event here and prevent default action */}
                        <a href="https://wa.me/966577373424" target="_blank" rel="noopener noreferrer" 
                        onClick={(e) => {
                            e.preventDefault(); // Prevent the default action
                            handleClick('whatsapp_button_click'); // Log the event and handle the action
                        }}>
                            <img src={headerImg} alt="Header Img" style={{width: '70%'}}/>
                        </a>
                    </div>}
            </TrackVisibility>
        </Col>

        </Row>
      </Container>
    </section>
  )
}
