import { Container, Row, Col, Tab, Nav,Card } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import pfp1 from "../assets/img/pfp1.png";
import pfp3 from "../assets/img/pfp3.png";
import pfp4 from "../assets/img/pfp4.png";
import pfp5 from "../assets/img/pfp5.png";
import pfp6 from "../assets/img/pfp6.png";

import TrackVisibility from 'react-on-screen';
import Carousel from 'react-bootstrap/Carousel';


export const Projects = () => {

  const projects = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
    },
  ];

  // Array of testimonials, replace with real data
  const testimonials = [
    {
      customerName: "فهد السعدون",
      comment: "غيّروا حياتي الدراسية، فزعوا لي في البحث وجبت درجات عالية، ما أقدر أشكرهم 🙏",
      rating: 4, // Rating out of 5
      img: pfp1 // Profile picture
    },
    {
      customerName: "لمى القحطاني",
      comment: "كنت متورطه في بحث التخرج لحد ما وصلت لـ فضاء الأبحاث، مو بس سوو لي عرض تقديمي جبار، كمان علموني كيف أطور نفسي في المرات الجاية 😊",
      rating: 5,
      img: pfp3
    },
    {
      customerName: "عزوز",
      comment: "أنصح فيهم بشدة! جودة العمل عالية وخدمة العملاء عسل، الله يعطيهم العافية 👌",
      rating: 5,
      img: pfp4
    },
    {
      customerName: "سعد العتيق",
      comment: "مرة ابداع هالموقع! خدماتهم في الابحاث والعروض ممتازة وسرعتهم بالتسليم شي ثاني 👍",
      rating: 5,
      img: pfp1
  },
  {
      customerName: "أميرة السهلي",
      comment: "تعاملت مع فضاء الأبحاث في أكثر من مشروع، وكل مرة اتفاجئ بجودة العمل واهتمامهم بالتفاصيل، يعطيهم العافية ❤️",
      rating: 4.5,
      img: pfp5
  },
  {
      customerName: "عبدالرحمن الزهراني",
      comment: "جربت خدماتهم والله أعجبتني كثير، خاصة بالأعمال الأكاديمية، يستاهلون الخمس نجوم",
      rating: 5,
      img: pfp1
  },
  {
      customerName: "عائشة البلوي",
      comment: "الموقع جداً مفيد للطلبة، انقذوني في وقت الشدة بسرعة واحترافية، الله يسعدهم 🙂",
      rating: 4,
      img: pfp3
  },
  {
      customerName: "مشعل القحطاني",
      comment: "ما شاء الله تبارك الله، أساتذة في الأعمال الأكاديمية، والتعامل مع العملاء فوق الممتاز، اتمنى لهم التوفيق 🌹",
      rating: 5,
      img: pfp6
  }
  
  ];
  

  return (
<section className="project" id="testimonials">
  <Container>
    <Row>
      <Col size={12}>
        <TrackVisibility>
          {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
              <h2>شهادات العملاء</h2>
              <p>مو كلامنا بس اللي يهم، اسمع من العملاء اللي استفادوا من خدماتنا وطوروا مستواهم الدراسي.</p>
              <Carousel controls={false} indicators={false} pause={false} interval={6000}>
                {testimonials.map((testimonial, index) => (
                  <Carousel.Item key={index}>
                    <Card className="testimonial-card bg-dark text-white rounded-card">
                      <Card.Body className="d-flex justify-content-center flex-column align-items-center">
                      <div className="profile-container">
                          <Card.Img variant="top" src={testimonial.img} alt="Profile" className="align-self-end profile-image" />
                      </div>

                        <Card.Title><h4>{testimonial.customerName}</h4></Card.Title>
                        <Card.Text>
                          <p>{testimonial.comment}</p>
                          <p>{'⭐'.repeat(testimonial.rating)}</p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>}
        </TrackVisibility>
      </Col>
    </Row>
  </Container>
  <img className="background-image-right" src={colorSharp2}></img>
</section>


  )
}
