import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-b7th.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyAcYRHHWiAe3rfYDk6srna5nbL9lBlUxIY",
  authDomain: "b7thspace-7c94e.firebaseapp.com",
  projectId: "b7thspace-7c94e",
  storageBucket: "b7thspace-7c94e.appspot.com",
  messagingSenderId: "876046797370",
  appId: "1:876046797370:web:839c8aca4e0cc5e1460303",
  measurementId: "G-KCWS7NNF4C"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "نساعدك في البحوث 🤫", "نسوي عروضك التقديمية 👀", "والمزيد! 🔥" ];
  const period = 1500;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(200);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }
  function handleClick() {
    // Log the button click event
    logEvent(analytics, 'button_click');
  
    // Perform the existing action
    window.open('https://wa.me/966577373424', '_blank');
  }
  return (
    <section className="banner" id="home">
      <Container dir='rtl'>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
          <TrackVisibility>
                {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                    <span className="tagline">مرحباً في b7th.space</span>
                    <h1>{`احنا فضاء الأبحاث`} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "نساعدك في البحوث 🤫", "نسوي عروضك التقديمية 👀", "والمزيد! 🔥" ]'><span className="wrap">{text}</span></span></h1>
                    <p>انطلق في طريقك الدراسي معانا، نحنا جاهزين نساعدك في الأبحاث والعروض. خدماتنا مصممة خصيصاً للطلاب. تعال وانضم لنا في هذي الرحلة لاكتشاف العالم الدراسي.</p>

                    <button onClick={handleClick}><ArrowRightCircle size={25} />ابدأ الآن</button>
                </div>}
            </TrackVisibility>

          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
